header a {
  margin-right: 15px;
  color: black; /* Change as per your design */
  text-decoration: none;
}

header a:hover {
  text-decoration: underline;
}

.nav {
  display: flex;
  align-items: center;
}

.logo {
  font-weight: bold;
  font-size: 20px;
}

body, html {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
}

a {
  color: #007bff;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

img {
  max-width: 100%;
  height: auto;
}

